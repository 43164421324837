<template lang="pug">
  v-container#entryguides(fluid='' tag='section' style="padding: 0px;")
    v-dialog(v-if="dialogImportGuides" v-model='dialogImportGuides' max-width="600px")
      v-card(style='padding-bottom:0;')
        v-toolbar(text='' color='primary' dark='')
          v-toolbar-title
            h4 Importar Guías
            v-btn(icon="" dark="" @click="dialogImportGuides = false" style="position: absolute; top: 0px; right: 10px;")
              v-icon.primary.rounded mdi-close
        v-card-text(style='padding-bottom: 0;')
          div(style="widht: 100% text-align: center;")
            a(class="download-excel" href="https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/formatos%2FPlantilla-Guias.xlsx?alt=media&token=583f2257-54bb-4599-9b39-808b25785dd3") Descargar Plantilla para Carga de Guías
            input#inputExcelGuide(type='file' style="padding-top: 10px; font-family: 'ceraProMedium', sans-serif !important;")
          v-row(style='align-items: center;')
            v-col(cols='12')
              p(style="margin-bottom: 0px;") Para asistencia contáctanos:
                v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
                  v-icon(color="black") mdi-whatsapp
        v-card-actions
          v-spacer
          v-btn.success-btn(text='' @click='dialogImportGuides = false' :loading='loadingSaveGuideImport')
            | Finalizar
    v-dialog(fullscreen="" v-model='dialogEntryGuide')
      v-card
        entry-guide-modal(@closeModal="closeEntryGuide" :entryGuideToEdit="entryGuideToEdit")
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    base-material-card.px-5.py-3(icon='mdi-clipboard-plus' title="Ingreso de Guías" style='min-height: 100vh;')
      v-container#inventory-table(fluid='' tag='section')
        v-row(style="margin-bottom: 0px;")
          v-col(cols="12" md="5" style="margin-top: 10px;")
            v-menu(ref='menuFrom' v-model='menuFrom' :close-on-content-click='false' :return-value.sync='dateFrom' transition='scale-transition' offset-y='' min-width='290px')
              template(v-slot:activator='{ on }')
                v-text-field(readonly v-model='dateFrom' label='Buscar Desde' prepend-icon='mdi-calendar' v-on='on')
              v-date-picker(@change='$refs.menuFrom.save(dateFrom)' v-model='dateFrom' no-title='' scrollable='' :max="dateUntil" style='margin:0px;' color="green lighten-1" :min="minDateForConsults")
          v-col(cols="10" md="5" style="margin-top: 10px;")
            v-menu(ref='menuUntil' v-model='menuUntil' :close-on-content-click='false' :return-value.sync='dateUntil' transition='scale-transition' offset-y='' min-width='290px')
              template(v-slot:activator='{ on }')
                v-text-field(readonly v-model='dateUntil' label='Buscar Hasta' prepend-icon='mdi-calendar' v-on='on')
              v-date-picker(@change='$refs.menuUntil.save(dateUntil)' v-model='dateUntil' no-title='' scrollable='' :min="dateFrom" style='margin:0px;' color="green lighten-1")
          v-col(cols="2" md="2" style="margin-top: 15px;")
            v-tooltip(bottom='')
              template(v-slot:activator='{ attrs, on }')
                v-btn(v-on="on" icon='' color='gray' style="background-color: RGB(1, 125, 71);" @click="filterByDate" :loading="loadingDataFilterDate")
                  v-icon(style="font-size: 30px; color: white;") mdi-magnify
              span.tooltips FILTRAR POR FECHA
          v-col(cols="12" md="6")
            v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
          v-col(cols="12" md="2")
            v-btn.mr-0(color='primary' rounded='' @click='addNewGuide' style="width: 100%;")
              strong
                | NUEVA
                br
                | GUÍA
          v-col(cols="12" md="2")
            v-btn.mr-0(color='green' rounded='' @click='importGuides' style="width: 100%;")
              strong
                | IMPORTAR
                br
                | GUÍAS
          v-col(cols="12" md="2")
            v-btn.mr-0(color='blue' rounded='' @click='downLoadExcelFile' style="width: 100%;")
              strong
                | DESCARGAR
                br
                | GUÍAS
        v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
        v-data-table.mx-2#tableGuides(:search="search" :headers="headers" v-show="!firstLoad" :items="guidesEntryLst" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" :sort-desc="true" calculate-widths :footer-props="{\'items-per-page-text':'Guías por página'\}")
          template(v-slot:[`item.status`]='{ item }')
            v-chip(:color="getColor(item.status)" small label) {{ item.status ? item.status : 'ACTIVO' }}
          template(v-slot:item.action="{ item }")
            v-row
              v-col(cols="6" md="6")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='blue' v-on="on" @click="editEntryGuide(item)")
                      v-icon(small) mdi-eye
                  span.tooltips Visualizar Guía
              v-col(cols="6" md="6")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(:disabled="!item.estado" text="" icon="" color='red' v-on="on" @click="")
                      v-icon(small) mdi-trash-can-outline
                  span.tooltips Anular Guía
          v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
          | Sin resultados
          v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
            | Sin datos
          v-flex(xs12='', sm10='', offset-sm1='')
</template>
<script>
  import moment from 'moment'
  import readXlsxFile from 'read-excel-file'
  import * as functions from '../../../functions.js'
  export default {
    components: {
      EntryGuideModal: () => import('@/views/dashboard/component/application/EntryGuideModal'),
    },
    data: () => ({
      minDateForConsults: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      loadingSaveGuideImport: false,
      dialogImportGuides: false,
      entryGuideToEdit: {
        codeGuide: '',
        referralGuide: '',
        codeProduct: '',
        dateEntry: moment(new Date()).format('YYYY-MM-DD'),
        item: '',
        weight: '',
        inputweight: '',
        kiloprice: '',
        discounts: '',
        total: '',
        plate: '',
        provider: '',
        state: 'ACTIVO',
        store: '',
        providerSelect: null,
      },
      loadingDataFilterDate: false,
      dialogEntryGuide: false,
      menuUntil: false,
      menuFrom: false,
      dateUntil: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      dateFrom: moment(new Date()).format('YYYY-MM-DD'),
      headers: [
        // { text: 'Nro. Verificado', value: 'codeGuide', align: 'left', filterable: true },
        { text: 'Guía. Remisión', value: 'referralGuide', align: 'left', filterable: true },
        // { text: 'Cod. Producto', value: 'codeProduct', align: 'left', filterable: true },
        { text: 'Fecha', value: 'dateEntry', align: 'left', filterable: true },
        { text: 'Item', value: 'item', align: 'left', filterable: true },
        { text: 'Peso', value: 'weight', align: 'left', filterable: true },
        { text: 'Total', value: 'total', align: 'left', filterable: true },
        { text: 'Proveedor Seleccionado', value: 'providerSelect.completeData', align: 'left', filterable: true },
        { text: 'Estado', value: 'status', align: 'left', filterable: true },
        { text: 'ACCIONES', align: 'center', value: 'action', sortable: false },
      ],
      cargando: false,
      firstLoad: false,
      ordenarPor: 'dateEntry',
      search: '',
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    }),
    computed: {
      plan () {
        return this.$store.state.suscriptionPlan.plan
      },
      guidesEntryLst () {
        return this.$store.state.entryguides.entryGuidesLst
      },
    },
    watch: {
      plan () {
        if (this.plan.diasConsulta) {
          this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
        }
      },
    },
    mounted () {
      if (this.plan.diasConsulta) {
        this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
      }
    },
    methods: {
      closeEntryGuide () {
        this.dialogEntryGuide = false
      },
      addNewGuide () {
        this.dialogEntryGuide = true
        this.entryGuideToEdit = {
          codeGuide: '',
          referralGuide: '',
          codeProduct: '',
          dateEntry: moment(new Date()).format('YYYY-MM-DD'),
          item: '',
          weight: '',
          inputweight: '',
          kiloprice: '',
          discounts: '',
          total: '',
          plate: '',
          provider: '',
          state: 'ACTIVO',
          store: '',
          providerSelect: null,
        }
      },
      editEntryGuide (item) {
        this.dialogEntryGuide = true
        this.entryGuideToEdit = item
      },
      importGuides () {
        this.dialogImportGuides = true
        setTimeout(() => {
          const inputExcel = document.getElementById('inputExcelGuide')
          inputExcel.addEventListener('change', () => {
            readXlsxFile(inputExcel.files[0]).then(async (rows) => {
              this.loadingSaveGuideImport = true
              const entryGuideDataSendLst = []
              let globalBan = true
              for (let i = 2; i < rows.length; i++) {
                let msg = 'El campo {CAMPO} de la fila {FILA} es incorrecto. Por favor ingrese el valor correcto para continuar.'
                if (!rows[i][1] || rows[i][1].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'GR')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  globalBan = false
                }
                if (!rows[i][3] || rows[i][3].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Nro. Verificado')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  globalBan = false
                }
                if (!rows[i][4] || rows[i][4].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Fecha')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  globalBan = false
                }
                if (!rows[i][5] || rows[i][5].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Codigo')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  globalBan = false
                }
                if (!rows[i][6] || rows[i][6].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Item')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  globalBan = false
                }
                if (!rows[i][7] || rows[i][7].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Peso')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  globalBan = false
                }
                if (!globalBan) {
                  this.dialogImportGuides = false
                  this.loadingSaveGuideImport = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: msg,
                  }
                  return
                }
                let strDate = null

                const arrDate = rows[i][4].toString().split('/')
                let stringDate = ''
                if (arrDate.length === 3) {
                  const year = parseFloat(arrDate[2]) > 2021 ? arrDate[2] : '20' + arrDate[2]
                  const month = arrDate[1]
                  const day = arrDate[0]
                  stringDate = '' + year + '-' + month + '-' + day
                  strDate = moment(new Date(stringDate)).add(1, 'days').format('YYYY-MM-DD')
                }

                if (arrDate.length !== 3) {
                  try {
                    strDate = moment(new Date(rows[i][4])).add(1, 'days').format('YYYY-MM-DD')
                    if (strDate.toUpperCase().includes('INVALID DATE')) {
                      const strDateVal = rows[i][4].toString().split('/')
                      let stringDate = ''
                      if (strDateVal.length === 3) {
                        const year = parseFloat(strDateVal[2]) > 2021 ? strDateVal[2] : '20' + strDateVal[2]
                        const month = strDateVal[1]
                        const day = strDateVal[0]
                        stringDate = '' + year + '-' + month + '-' + day
                      }
                      strDate = moment(new Date(stringDate)).add(1, 'days').format('YYYY-MM-DD')
                    }
                  } catch (e) {
                    this.dialogImportGuides = false
                    this.loadingSaveGuideImport = false
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error en el formato de fecha agregado por favor verificar.',
                    }
                    return
                  }
                }

                const entryGuide = {
                  codeGuide: rows[i][3],
                  referralGuide: rows[i][1],
                  codeProduct: rows[i][5].toString(),
                  dateEntry: strDate,
                  item: rows[i][6],
                  weight: rows[i][7],
                  plate: rows[i][2] ? rows[i][2] : '',
                  provider: rows[i][8] ? rows[i][8] : '',
                  state: 'ACTIVO',
                  store: rows[i][0] ? rows[i][0] : '',
                }
                const productData = {
                  codArticle: entryGuide.codeProduct,
                  barcode: entryGuide.codeProduct,
                  name: entryGuide.item,
                  description: entryGuide.item,
                  type: {
                    codigo: '01',
                    texto: 'BIEN',
                  },
                  quantity: '1',
                  activeValue: '0',
                  activeWValue: '0',
                  activeValueWholesaler: '0',
                  activeWValueWholesaler: '0',
                  purchaseValue: '0',
                  purchaseWValue: '0',
                  productKey: '',
                  completeData: entryGuide.codeProduct + ' | ' + entryGuide.item + ' | ' + entryGuide.item + ' | ' + entryGuide.codeProduct,
                  tarifaiva: {
                    codigo: '2',
                    texto: '12%',
                    valor: '12',
                  },
                  irbpnr: '',
                  tarifaice: '',
                  totalValue: '',
                  inecCode: null,
                }
                const arrGR = rows[i][1].toString().split('-')
                const strGR = arrGR[0] + arrGR[1] + arrGR[2]
                await this.$store.dispatch('provider/loadProviderByGuideImport', strGR).then(res => {
                  if (res) {
                    if (res.length > 0) {
                      entryGuide.providerSelect = res[0]
                    }
                  }
                })
                const dataSend = {
                  entryData: entryGuide,
                  productData: productData,
                  uidUser: this.$store.state.user.user.uid,
                  update: false,
                }
                entryGuideDataSendLst.push(dataSend)
              }
              if (entryGuideDataSendLst.length > 0) {
                this.$store.dispatch('entryguides/saveEntryGuideLst', entryGuideDataSendLst).then(res => {
                  this.loadingSaveGuideImport = false
                  this.dialogImportGuides = false
                  if (res) {
                    this.snackbar = {
                      show: true,
                      color: 'green',
                      text: 'Proceso realizado de manera correcta.',
                    }
                    this.$emit('closeModal')
                  } else {
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de realizar el proceso.',
                    }
                  }
                }, reject => {
                  this.loadingSaveGuideImport = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'Error al momento de realizar el proceso.',
                  }
                })
              } else {
                this.dialogImportGuides = false
                this.loadingSaveGuideImport = false
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'No se tiene registros para agregar.',
                }
              }
            }).catch((e) => {
              this.dialogImportGuides = false
              this.loadingSaveGuideImport = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de cargar el archivo. Verifique el archivo.',
              }
            })
          })
        }, 500)
      },
      downLoadExcelFile () {
        const data = []
        if (this.guidesEntryLst) {
          for (let i = 0; i < this.guidesEntryLst.length; i++) {
            data.push({
              Bodega: this.guidesEntryLst[i].store,
              GR: this.guidesEntryLst[i].referralGuide,
              Placa: this.guidesEntryLst[i].plate,
              'Nro. Verificado': this.guidesEntryLst[i].codeGuide,
              Fecha: this.guidesEntryLst[i].dateEntry,
              Codigo: this.guidesEntryLst[i].codeProduct,
              Item: this.guidesEntryLst[i].item,
              Peso: this.guidesEntryLst[i].weight,
              Proveedor: this.guidesEntryLst[i].provider,
              'Peso de Emtrada': this.guidesEntryLst[i].inputweight ? this.guidesEntryLst[i].inputweight : '',
              'Precio por Kilo': this.guidesEntryLst[i].kiloprice ? this.guidesEntryLst[i].kiloprice : '',
              Total: this.guidesEntryLst[i].total ? this.guidesEntryLst[i].total : '',
            })
          }
          functions.JSONToCSVConvertor(data, 'Lista-Guias', true)
        }
      },
      async filterByDate () {
        const data = {
          firstDay: this.dateFrom,
          lastDay: this.dateUntil,
        }
        await this.$store.dispatch('entryguides/loadEntryGuidesFilterDate', data)
      },
      getColor (valor) {
        if (!valor || valor === 'ACTIVO') {
          return 'green'
        } else {
          return 'red'
        }
      },
    },
  }
</script>
